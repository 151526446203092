/*
Template Name: Nazox - Responsive Bootstrap 4 Admin Dashboard
Author: Themesdesign
Contact: themesdesign.in@gmail.com
File: Main Css File
*/

/*Fonts
/* @import "custom/fonts/fonts";*/
/*Core files*/
@import './node_modules/bootstrap/scss/functions';
@import './node_modules/bootstrap/scss/variables';
@import 'variables';
@import './node_modules/bootstrap/scss/mixins.scss';

/* Structure*/
@import 'custom/structure/topbar';
@import 'custom/structure/vertical';
@import 'custom/structure/layouts';

/* Plugins*/
@import 'custom/plugins/custom-scrollbar';
@import 'custom/plugins/apexcharts';

/* Pages*/
@import 'custom/pages/chat';
