.fadein-animation {
	animation: fadeIn linear 1s;
	-webkit-animation: fadeIn linear 1s;
	-moz-animation: fadeIn linear 1s;
	-o-animation: fadeIn linear 1s;
	-ms-animation: fadeIn linear 1s;
}

.fast-fadein {
	animation: fadeIn linear 0.5s;
	-webkit-animation: fadeIn linear 0.5s;
	-moz-animation: fadeIn linear 0.5s;
	-o-animation: fadeIn linear 0.5s;
	-ms-animation: fadeIn linear 0.5s;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-moz-keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-webkit-keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-o-keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-ms-keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
