@use 'src/utils';
@use 'projects/ripple' as ripple;

// Tooltip overrides
.mdc-tooltip {
	font-size: 0.75rem;
	line-height: 0.875rem;
	padding: 0.5rem !important;
	border-radius: 0.5rem;
	box-shadow: 0 0 3px rgba(0, 34, 68, 0.3);
	background: ripple.$white !important;
	color: utils.$dark-blue-120 !important;
	pointer-events: none;
	cursor: default;
	width: auto;
	font-weight: 400;

	&.title-tooltip {
		text-align: center;
	}
}

.mdc-tooltip__surface {
	color: ripple.$text-secondary !important;
	font-weight: 400;
	text-align: inherit !important;
	background: ripple.$white !important;

	.no-network-information & {
		color: ripple.$error !important;
		font-weight: 500;
	}
}

// Tabs
.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
	background-color: utils.$healthee-blue;
}

.mat-mdc-tab-group.mat-primary,
.mat-mdc-tab-nav-bar.mat-primary {
	.mdc-tab.mat-mdc-tab-link > .mdc-tab-indicator  > .mdc-tab-indicator__content.mdc-tab-indicator__content--underline {
		background-color: utils.$healthee-blue;
	}
}

.mat-mdc-tab-link {
	height: 4rem;
	color: utils.$dark-blue-shade-1;
	opacity: 1 !important; // override mat-tab internal design when the tab is not focused.
	text-decoration: none !important;

	&.mdc-tab-indicator--active > .mdc-tab__content > .mdc-tab__text-label {
		color: utils.$azure-radiance;
	}

	&:hover > .mdc-tab__content > .mdc-tab__text-label {
		text-decoration: none !important;
		color: utils.$azure-radiance;
	}
}

.mat-mdc-tab-list {
	max-width: 100%;
}

.mat-mdc-tab-links {
	.mdc-tab.mat-mdc-tab-link > .mdc-tab-indicator  > .mdc-tab-indicator__content.mdc-tab-indicator__content--underline {
		background-color: utils.$healthee-blue !important; // override mat-tab internal design when the tab is selected
	}
}


// Snackbars
.snackbar-success {
	background-color: utils.$success-5 !important;
	border: 1px solid utils.$success !important;
	margin-top: calc(env(safe-area-inset-top) + 1.5rem) !important;
	border-radius: 0.25rem;

	.mdc-snackbar__surface {
		background-color: initial !important;
	}

	.mat-mdc-snack-bar-label {
		color: utils.$text-color-primary !important;
	}

	.mat-simple-snackbar-action {
		button {
			color: utils.$text-color-primary !important;
		}
	}
}

.snackbar-failure {
	background-color: utils.$error-5 !important;
	border: 1px solid utils.$error !important;
	color: utils.$text-color-primary !important;
	margin-top: calc(env(safe-area-inset-top) + 1rem) !important;
	border-radius: 0.25rem;

	.mdc-snackbar__surface {
		background-color: initial !important;
	}

	.mat-mdc-snack-bar-label {
		color: utils.$text-color-primary !important;
	}

	.mat-simple-snackbar-action {
		button {
			color: utils.$text-color-primary !important;
		}
	}
}

// Drawer/sidebar
.mat-drawer-backdrop.mat-drawer-shown {
	background-color: rgba(0, 27, 54, 0.4) !important;
}

mat-drawer {
	overflow-y: initial !important;
}

.mat-drawer-inner-container {
	overflow: initial !important;
}

// Progress bar
.mat-progress-bar-fill::after {
	background-color: utils.$healthee-blue;
}

.mat-progress-bar-buffer {
	background: #e4e8eb;
}

// Slide toggle
.mat-slide-toggle {
	&.mat-slide-toggle .mat-slide-toggle-bar {
		background-color: utils.$border-color-light-blue;
		width: 3rem;
		height: 2rem;
		border-radius: 1rem;

		.mat-slide-toggle-thumb-container {
			height: 1.75rem;
			width: 1.75rem;
			top: 0.125rem;
			left: 0.125rem;

			.mat-slide-toggle-thumb {
				height: 1.75rem;
				width: 1.75rem;
				background-color: white;
			}

			.mat-ripple .mat-ripple-element {
				background-color: utils.$healthee-blue;
			}
		}
	}

	&.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
		background-color: utils.$healthee-blue;
	}

	&.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
		background-color: utils.$healthee-blue;
	}
}

// Dialog
.mdc-dialog__surface {
	padding: 1rem !important;
	border-radius: 1rem !important;
}
