// only edit this file when the styles exist in the figma design system

$blue-5: #f2f9ff;
$blue-20: #cce5ff;
$blue-40: #99cbff;
$blue-60: #66b2ff;
$blue-80: #3398ff;
$blue: #007eff;
$blue-120: #0065cc;
$blue-160: #004182;
$blue-180: #002244;

$orange-5: #fff6f2;
$orange-20: #ffdccc;
$orange-40: #ffba99;
$orange-60: #fe9767;
$orange-80: #fe7534;
$orange: #fe5201;
$orange-120: #cb4201;
$orange-160: #822a01;

$yellow-5: #fffbf2;
$yellow-20: #fff0cc;
$yellow-40: #ffe299;
$yellow-60: #ffd367;
$yellow-80: #ffc434;
$yellow: #ffb601;
$yellow-120: #ffb601;
$yellow-160: #ffb601;

$success-5: #f5fbf3;
$success-20: #d8eed1;
$success-40: #b1dda3;
$success: #3bab18;

$error-5: #fcf3f3;
$error-20: #f4d0d0;
$error: #ca1414;

// only edit this file when the styles exist in the figma design system

$cream: #f7f7ef;
$purple: #3f0031;
$light-blue: #f2f9ff;
$white: #ffffff;

$border-base: #dee7f2;
$border-strong: #dde0e2;

$text-base: #001e3d;
$text-secondary: #405c79;
$text-light: #8092a5;
$text-light-2: #bfc8d2;

// only edit this file when the styles exist in the figma design system
