.ps__rail-y.ps--clicking .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y:hover > .ps__thumb-y {
	width: 6px !important;
	background-color: transparent !important;
}

.ps__thumb-y {
	background-color: #a2adb7 !important;
}

.ps--active-x > .ps__rail-x,
.ps--active-y > .ps__rail-y {
	background-color: transparent !important;
}
