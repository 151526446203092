.developer-mode-input {
	height: 3rem;
	font-size: 1.5rem;
	padding: 0.5rem;
	color: var(--healthee-blue);
}

.ionic-loading-indicator {
	--background: white;
	--spinner-color: var(--healthee-blue);

	color: var(--insu-text);
}

ion-app {
	margin-top: constant(safe-area-inset-top);
	margin-top: env(safe-area-inset-top);
}

ion-toggle {
	--background-checked: var(--healthee-blue);
	--track-background-checked: var(--healthee-blue);
}
