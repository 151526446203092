/* _layouts.scss*/

body[data-layout-size='boxed'] {
	background-color: $boxed-body-bg;
	#layout-wrapper {
		background-color: $body-bg;
		max-width: $boxed-layout-width;
		margin: 0 auto;
		box-shadow: $box-shadow;
	}

	#page-topbar {
		max-width: $boxed-layout-width;
		margin: 0 auto;
	}

	.footer {
		margin: 0 auto;
		max-width: #{$boxed-layout-width};
	}

	&.vertical-collpsed {
		.footer {
			max-width: $boxed-layout-width;
		}
	}
}

/* Horizontal Boxed Layout*/

body[data-layout='horizontal'][data-layout-size='boxed'] {
	#page-topbar,
	#layout-wrapper,
	.footer {
		max-width: 100%;
	}
	.container-fluid,
	.navbar-header {
		max-width: $boxed-layout-width;
	}
}
