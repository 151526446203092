@import './src/styles/colors.scss';
@import './src/styles/typography.scss';
@import './src/styles/shadows.scss';

.ripple-autocomplete {
	// Important is needed to override bottom-left and bottom-right styles of material
	border-radius: 1rem !important;
	border: 1px solid $border-base;
	box-shadow: 0 0.5rem 1.5rem 0 rgba($text-base, 0.08) !important;
	margin: 0.5rem 0;
	width: 100%;
	padding: 0 !important;

	&.input-with-icon {
		position: relative;
		left: -2.5rem;
	}

	mat-option {
		height: auto;
		line-height: normal;
		padding: 0;
	}
}
