@use './../../../utils.scss';

.bt-regular {
	font-size: 1rem;
	font-weight: 400;
	padding: 0.75rem 2rem;
	border-radius: 100px;
	color: var(--insu-bg) !important;
	background-color: var(--primary);

	&[disabled] {
		color: var(--insu-text) !important;
		box-shadow: inset 0 0 0 100vh var(--insu-disabled);
		cursor: initial;
	}
}

.bt-primary {
	font-size: 1rem;
	padding: 0.75rem 1.75rem;
	outline: 0;
	cursor: pointer;
	user-select: none;
	border-radius: 100rem;
	font-weight: 400;
	border: solid 1.1px transparent;
	background-origin: border-box;
	background-clip: border-box;
	background-image: var(--healthee-gradient);
	color: var(--insu-bg);
	box-shadow: inset 0 0 0 100vh var(--primary);

	&[disabled] {
		color: var(--insu-text);
		box-shadow: inset 0 0 0 100vh var(--insu-skipped);
		cursor: initial;
		border: none;
		color: var(--white);
	}
}

.bt-secondary {
	font-size: 1rem;
	font-weight: 400;
	padding: 0.75rem 1.75rem;
	color: var(--insu-text);
	outline: 0;
	cursor: pointer;
	user-select: none;
	border-radius: 100rem;
	border: solid 1.1px transparent;
	background-origin: border-box;
	background-clip: border-box;
	background-image: var(--healthee-gradient);
	box-shadow: inset 0 0 0 100vh var(--insu-bg);

	&[disabled] {
		color: var(--insu-text);
		box-shadow: inset 0 0 0 100vh var(--insu-disabled);
		cursor: initial;
	}
}

.bt-teritary {
	font-size: 1rem;
	padding: 0.75rem 1.75rem;
	cursor: pointer;
	color: utils.$healthee-blue;
	font-style: normal;
	line-height: 100%;
	border: 1px solid #dee7f2;
	border-radius: 2rem;
	background-color: #fff;
	height: 3rem;
}

.bt-gradient {
	color: var(--insu-text);
	outline: 0;
	cursor: pointer;
	user-select: none;
	border-radius: 100rem;
	font-weight: 400;
	border: solid 1.1px transparent;
	background-origin: border-box;
	background-clip: border-box;
	background-image: var(--healthee-gradient);
	box-shadow: inset 0 0 0 100vh var(--insu-bg);
	transition: all 0.3s ease;

	&:hover {
		color: var(--insu-bg);
		box-shadow: inset 0 0 0 0 transparent;
	}
	&.active {
		color: var(--insu-bg);
		box-shadow: inset 0 0 0 100vh var(--primary);
		transition: all 0.1s ease;
	}

	&.disabled {
		color: var(--insu-text);
		box-shadow: inset 0 0 0 100vh var(--insu-disabled);
		cursor: initial;
	}
	&[disabled] {
		color: var(--insu-text);
		box-shadow: inset 0 0 0 100vh var(--insu-disabled);
		cursor: initial;
	}
}

input[type='checkbox'] {
	appearance: none;
	width: 1.25rem;
	height: 1.25rem;
	border-radius: 0.25rem;
	background: white;
	border: 1px solid var(--insu-text);
	padding: 0;
	box-sizing: content-box !important;

	&:not(:checked):hover:after {
		content: '';
		width: 0.75rem;
		height: 0.75rem;
		border-radius: 3px;
		background-image: var(--healthee-gradient);
		display: inline-flex;
		margin-top: 0.25rem;
		margin-left: 0.25rem;
	}

	&:checked:after {
		content: '';
		width: 0.75rem;
		height: 0.75rem;
		border-radius: 3px;
		background-color: var(--insu-text);
		display: inline-flex;
		margin-top: 0.25rem;
		margin-left: 0.25rem;
	}
}

button.bt, a.bt {
	font-size: 1rem;
	height: 2.5rem;
	padding: 0 1.75rem;
	outline: 0;
	cursor: pointer;
	border-radius: 100rem;
	font-weight: 400;

	&:not(.selectable) {
		user-select: none;
	}

	&.no-padding {
		padding: 0;
		height: 2rem;
		width: 2rem;
		border-radius: 50%;
	}

	&.primary-blue {
		background: var(--healthee-blue);
		color: var(--insu-bg);

		&.selectable::selection {
			background: var(--insu-bg);
			color: var(--healthee-blue);
		}
	}

	&.secondary-blue {
		border: 1px solid var(--healthee-blue-light);
		color: var(--healthee-blue);
		background: var(--insu-bg);
	}

	&[disabled] {
		background: var(--insu-disabled);
		cursor: initial;
	}
}

.bt-link {
	background: none;
	cursor: pointer;
	color: var(--healthee-blue);
}
