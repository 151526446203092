@use 'projects/ripple';
@use 'src/utils';

.mat-mdc-checkbox.mat-mdc-checkbox-checked .mdc-form-field .mdc-checkbox .mdc-checkbox__background {
	background-color: ripple.$blue !important;
	border-color: ripple.$blue !important;
	svg {
		color: ripple.$white !important;
	}
}

.mdc-checkbox .mdc-checkbox__native-control:focus:checked ~ .mdc-checkbox__ripple {
	background-color: ripple.$blue !important;
}

.mat-form-field-appearance-outline {
	line-height: 1.125;
}

.mat-form-field-appearance-outline .mdc-notched-outline--notched {
	color: utils.$healthee-blue;
}

.mat-form-field-appearance-outline.mat-focused .mdc-notched-outline--notched {
	color: utils.$healthee-blue;
}

.mat-form-field-appearance-outline .mdc-notched-outline__leading {
	border-radius: 24px 0 0 24px !important;
	min-width: 24px !important;
}

.mat-form-field-appearance-outline .mdc-notched-outline__trailing {
	border-radius: 0 24px 24px 0 !important;
	min-width: 24px !important;
}

.mat-form-field-appearance-outline .mat-mdc-form-field-flex:hover .mdc-notched-outline--notched {
	.mdc-notched-outline__leading,
	.mdc-notched-outline__trailing,
	.mdc-notched-outline__notch {
		border-width: 1px !important;
	}
}

.mat-focused .mat-form-field-appearance-outline .mat-mdc-form-field-flex .mdc-notched-outline--notched {
	.mdc-notched-outline__leading,
	.mdc-notched-outline__trailing,
	.mdc-notched-outline__notch {
		border-width: 1px !important;
	}
}

mat-option:hover:not(.mat-option-disabled) {
	background: var(--healthee-blue-light) !important;
}

.mat-mdc-text-field-wrapper {
	.mat-mdc-form-field-flex .mdc-notched-outline {
		.mdc-notched-outline__leading,
		.mdc-notched-outline__notch,
		.mdc-notched-outline__trailing {
			border-color: ripple.$border-strong;
			transition: 200ms ease-in-out;
		}
	}

	&:hover,
	&.mdc-text-field--focused {
		.mat-mdc-form-field-flex .mdc-notched-outline {
			.mdc-notched-outline__leading,
			.mdc-notched-outline__notch,
			.mdc-notched-outline__trailing {
				border-color: ripple.$blue !important;
			}
		}
		&.mdc-text-field--disabled {
			.mat-mdc-form-field-flex .mdc-notched-outline {
				.mdc-notched-outline__leading,
				.mdc-notched-outline__notch,
				.mdc-notched-outline__trailing {
					border-color: ripple.$border-strong !important;
				}
			}
		}
	}
}

.mat-mdc-form-field-subscript-wrapper {
	display: none;
}

.mat-datepicker-toggle {
	.mdc-icon-button.mat-mdc-icon-button {
		width: 40px;
		height: 40px;
		padding: 10px 0;

		svg {
			width: 21px;
			height: 21px;
		}
	}
}

.mat-calendar-body-selected {
	color: ripple.$white !important;
	background-color: ripple.$blue !important;
}

// We should display ripple arrow instead
.mat-mdc-select-arrow-wrapper {
	display: none !important;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
	color: utils.$healthee-blue;
	background: utils.$white;
}

.badge-color-healthee-blue {
	.mat-badge-content {
		background: utils.$healthee-blue !important;
	}
}

//$accent-palette => 500
.mat-focused .mat-form-field-required-marker {
	color: utils.$error;
}

.mat-form-field-appearance-legacy.mat-focused .mat-form-field-ripple {
	height: 1px !important;
}

.mat-mdc-form-field-focus-overlay {
	background-color: transparent !important;
}

.mdc-text-field--filled {
	background-color: transparent !important;
}

.mdc-text-field .mdc-floating-label,
.mdc-floating-label--float-above,
.mat-mdc-select,
.mat-mdc-form-field-type-mat-input,
.mat-mdc-select-panel mat-option {
	font-size: 14px !important;
}

// In outlined inputs we want the value to start from where border radius ends
.mat-form-field-appearance-outline .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
	margin-left: 10px !important;
	min-height: initial;
}

mat-form-field label,
mat-checkbox label {
	margin: initial;
}

.mat-mdc-form-field-type-mat-select {
	cursor: pointer;

	.mdc-text-field .mdc-floating-label:not(.mdc-floating-label--float-above) {
		top: 1.4rem;
	}
}

.mdc-text-field {
	padding: 0 10px !important;
}

.mat-form-field-appearance-outline .mat-mdc-form-field-infix {
	height: 3rem;
	display: flex !important;
	flex-direction: column;
	justify-content: center;
}

// Options and optgroups
.mat-mdc-select-panel {
	padding: 0 !important;
}

mat-optgroup mat-option {
	height: unset;
	padding: 8px 16px !important;
}

mat-option {
	&.error-message-option {
		margin-left: 0.5rem;
		white-space: normal;
		line-height: 1.5rem;
	}
}

.mat-mdc-optgroup-label {
	color: ripple.$text-base;
	font-weight: 500;
}
