/* apexcharts.scss*/
.apex-charts {
	min-height: 10px !important;
	text {
		fill: $gray-500;
	}
	.apexcharts-canvas {
		margin: 0 auto;
	}
}

.apexcharts-legend-series {
	font-weight: $font-weight-medium;
}

.apexcharts-gridline {
	pointer-events: none;
	stroke: $apex-grid-color;
}

.apexcharts-legend-text {
	color: $gray-600 !important;
	font-size: 13px !important;
}

.apexcharts-pie-label {
	fill: $white !important;
}

.apexcharts-yaxis,
.apexcharts-xaxis {
	text {
		fill: $gray-500;
	}
}
