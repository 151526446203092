.navbar-header {
	display: flex;
	-ms-flex-pack: justify;
	align-items: center;
	margin: 0 auto;
	height: $header-height;
	background-color: var(--insu-bg);
	.dropdown.show {
		.header-item {
			background-color: darken($header-bg, 2%);
		}
	}
}

.navbar-brand-box {
	padding: 0 1.5rem;
}

.app-search {
	padding: calc(#{$header-height - 38px} / 2) 0;

	.form-control {
		border: none;
		height: 38px;
		padding-left: 40px;
		padding-right: 20px;
		background-color: $topbar-search-bg;
		box-shadow: none;
		border-radius: 30px;
	}
	span {
		position: absolute;
		z-index: 10;
		font-size: 16px;
		line-height: 38px;
		left: 13px;
		top: 0;
		color: $gray-600;
	}
}

.header-item {
	height: $header-height;
	box-shadow: none !important;
	color: $insu-text;
	border: 0;
	border-radius: 0px;
}

.header-profile-user {
	height: 36px;
	width: 36px;
	background-color: $gray-300;
	padding: 3px;
}

.user-dropdown {
	.dropdown-item {
		i {
			display: inline-block;
		}
	}
}

.notification-item {
	.media {
		padding: 0.75rem 1rem;

		&:hover {
			background-color: $gray-300;
		}
	}
}

body[data-topbar='dark'] {
	#page-topbar {
		background-color: $header-dark-bg;
	}
	.navbar-header {
		.dropdown.show {
			.header-item {
				background-color: rgba($white, 0.05);
			}
		}

		.waves-effect .waves-ripple {
			background: rgba($white, 0.4);
		}
	}

	.app-search {
		.form-control {
			background-color: rgba($topbar-search-bg, 0.07);
			color: $white;
		}
		span,
		input.form-control::-webkit-input-placeholder {
			color: rgba($white, 0.5);
		}
	}
}
