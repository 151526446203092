@use 'projects/ripple' as ripple;
@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import '../src/assets/scss/healthee-style/buttons.scss';
@import '../src/assets/scss/healthee-style/healthee-buttons.scss';
@import '../src/assets/scss/healthee-style/animations.scss';
@import 'assets/scss/angular-material/mat-theme';
@import 'assets/scss/angular-material/mat-overrides';
@import 'assets/scss/angular-material/mat-inputs';
@import '../node_modules/bootstrap/scss/bootstrap';
@import 'utils';

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-box-shadow: 0 0 0 30px white inset !important;
	-webkit-text-fill-color: var(--insu-text) !important;
}

// overriding all material elements with letter-spacing normal
[class*='mat-'] {
	letter-spacing: normal !important;
}

body {
	margin: 0;

	--healthee-font: 'Roboto', 'Helvetica Neue', 'Ariel', 'Helvetica', sans-serif;
}

body,
.mat-drawer-container {
	color: $text-color-primary;
}

.mat-typography {
	font-family: var(--healthee-font);
}

// TODO: Move into a dedicated messages/app-manager styles file under /assets/healthee-styles
.healthee-snackbar {
	color: var(--healthee-yellow);
}

.healthee-snackbar-ionic {
	color: var(--healthee-yellow);
	--background: rgb(50, 50, 50);
	--button-color: var(--healthee-red);
}

img.grayscale-svg {
	-webkit-filter: grayscale(1); /* Google Chrome & Safari 6+ */
	filter: grayscale(1);
}

.intercom-lightweight-app {
	z-index: 1000 !important;
}

.ellipsis {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

@include genUtilClasses;

.tooltip-container {
	height: 1rem;
	width: 1rem;
	border-radius: 1rem;
	font-size: 0.5rem;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 0.25rem;

	&:hover {
		cursor: pointer;
	}

	img {
		max-width: 100%;
	}
}

button.bt[disabled] {
	background-color: var(--healthee-blue-azur); // TODO: should be on _button.scss
}

.version-update-progress-bar {
	.mat-progress-bar-element {
		height: 3px !important;
		border-radius: 2px;
	}

	.mat-progress-bar-fill::after {
		background-color: #888888;
		border-radius: 2px;
	}

	.mdc-linear-progress__bar-inner {
		border-color: #fd5201;
	}

	.mdc-linear-progress__buffer-bar {
		background-color: rgba(253, 99, 27, 0.27);
	}

	.mat-mdc-progress-bar {
		--mdc-linear-progress-active-indicator-height: 2px;
		--mdc-linear-progress-track-height: 2px;
		--mdc-linear-progress-track-shape: 0;
	}
}

//google maps elements
.gmnoprint,
.gm-style-cc {
	display: none !important;
}

.drawer-panel {
	position: absolute;
	top: 0;
	right: 0;
	width: 45rem;
	max-width: 100%;
	height: 100%;
	background: $white;

	&.plan-details-drawer {
		width: 56.5rem;
	}

	&.plan-filters-drawer {
		width: 27.25rem;
		background: transparent;
	}
}

a {
	cursor: pointer;
	color: $azure-radiance;
}

a:hover {
	color: $azure-radiance-120;
}

.highlighted {
	color: $healthee-blue;
	font-weight: 400;
}

.inaccessible {
	pointer-events: none;
	opacity: 0.5;
}

app-comparison-table-page {
	.mat-form-field-wrapper {
		margin: 0 !important;
		padding-bottom: 0;
	}

	.mat-form-field-infix {
		border-top: 0;
	}
}

// V16 overrides
.mat-mdc-form-field-focus-overlay {
	background-color: transparent !important;
}

.mdc-text-field--filled {
	background-color: transparent !important;
}

.mdc-text-field {
	padding: 0 10px !important;
}

.mdc-dialog__surface {
	padding: 1rem !important;
	border-radius: 1rem !important;
}

label {
	margin: initial;
}

a[mat-tab-link] {
	text-decoration: none !important;
}

.has-tooltip {
	text-decoration: underline;
	text-decoration-style: dashed;
	text-decoration-color: ripple.$text-light-2;
	text-decoration-thickness: 1px;
	text-underline-offset: 5px;
	cursor: pointer;
}

.grecaptcha-badge {
	z-index: 2;
	visibility: hidden;
}
.grecaptcha-badge-visible {
	visibility: visible;
}
