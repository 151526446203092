@import './node_modules/bootstrap/scss/functions';
@import './node_modules/bootstrap/scss/variables.scss';

/* Variables*/
/* custom-variables*/
$healthee-primary: #002244;

/* Capacitor */
// set only for mobile app, 0 for browsers
$capacitor-nav-height: 0px;

/* Vertical Sidebar - Default Light*/
$sidebar-bg: $healthee-primary;
$healthee-sidebar-selected-item-bg: rgb(0, 22, 48);
$sidebar-menu-item-color: #74788d;
$sidebar-menu-sub-item-color: #7c8a96;
$sidebar-menu-item-icon-color: --insu-text;
$sidebar-menu-item-hover-color: #d7e4ec;
$sidebar-menu-item-active-color: #d7e4ec;

/* Vertical Sidebar - Dark*/
$sidebar-dark-bg: #252b3b; /*252b3b*/
$sidebar-dark-menu-item-color: #8590a5;
$sidebar-dark-menu-sub-item-color: #7c8a96;
$sidebar-dark-menu-item-icon-color: #8590a5;
$sidebar-dark-menu-item-hover-color: --insu-text;
$sidebar-dark-menu-item-active-color: #d7e4ec;

/* Topbar - Default Light*/
$header-height: 70px;
$header-bg: #ffffff;
$header-item-color: #636e75;

/* Topbar - Dark*/
$header-dark-bg: #252b3b;
$header-dark-item-color: #2b3244;

/* Topbar Search*/
$topbar-search-bg: #2b3244;

/* Bottom nav */
$bottom-nav-height: 7rem;

/* Horizontal nav*/
$topnav-bg: #fff;

$menu-item-color: #74788d;
$menu-item-active-color: #5664d2;

/* Right Sidebar*/
$rightbar-width: 280px;

/* Display*/
$display-none: none;
$display-block: block;

/* Boxed layout width*/
$boxed-layout-width: 1300px;
$boxed-body-bg: #dee7ec;

/* Font Weight*/
$font-weight-medium: 500;
$font-weight-semibold: 600;

/* apex charts*/
$apex-grid-color: #f8f9fa;

/* Custom Font*/

/* Variables*/
/*
/* Variables should follow the `$component-state-property-size` formula for*/
/* consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.*/

/* Color system*/

$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #eff2f7;
$gray-300: #f1f5f7;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #74788d;
$gray-700: #505d69;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

$grays: ();
/* stylelint-disable-next-line scss/dollar-variable-default*/
$grays: map-merge(
	(
		'100': $gray-100,
		'200': $gray-200,
		'300': $gray-300,
		'400': $gray-400,
		'500': $gray-500,
		'600': $gray-600,
		'700': $gray-700,
		'800': $gray-800,
		'900': $gray-900,
	),
	$grays
);

$blue: #5438dc;
$indigo: #564ab1;
$purple: #5664d2;
$pink: #e83e8c;
$red: #ff3d60;
$orange: #f1734f;
$yellow: #fcb92c;
$green: #1cbb8c;
$teal: #050505;
$cyan: #4aa3ff;

$insu-bg: #ffffff;
$insu-bobo: #b0b0b0;
$insu-gray: #aeaab4;
$insu-gray2: #8d8d91;
$insu-text: $healthee-primary;
$insu-skipped: #aeaab4;
$insu-disabled: #f2f2f3;
$insu-turkiz: #3dcbe1;
$insu-light-turkiz: #98f0fe;
$insu-invalid-bg: #ffe6e6;
$insu-blue: #444593;
$healthee-yellow: hsl(43, 100%, 50%); // #FFB601;
$healthee-red: hsl(19, 100%, 50%); // #FE5201;
$healthee-blue: hsl(210, 100%, 50%); // #007EFF;
$healthee-blue-transparent: hsla(210, 100%, 50%, 0.2); // #007EFF;
$healthee-gradient: linear-gradient(135deg, $healthee-blue, $healthee-yellow, $healthee-red);
$healthee-gradient-light: linear-gradient(
	105.41deg,
	rgba(0, 126, 255, 0.06) 13.68%,
	rgba(255, 182, 1, 0.06) 48.97%,
	rgba(254, 82, 1, 0.06) 85.55%
);
$healthee-grey-text: #898989;
$healthee-blue-sky: #f4f9ff;
$healthee-blue-sky-hover: #e8f4fe;
$healthee-blue-light: #dee7f2;
$healthee-dark-blue-120: '#001E3D';
$healthee-blue-azur: #cce5ff;
$healthee-dark-blue: #405c79;
$healthee-white-light: #dde0e2;
$healthee-green-light: rgba(59, 171, 24, 0.05);

$colors: ();

$colors: map-merge(
	(
		'capacitor-nav-height': $capacitor-nav-height,
		'header-height': $header-height,
		'bottom-nav-height': $bottom-nav-height,
		'insu-bg': $insu-bg,
		'insu-bobo': $insu-bobo,
		'insu-turkiz': $insu-turkiz,
		'insu-light-turkiz': $insu-light-turkiz,
		'insu-invalid-bg': $insu-invalid-bg,
		'insu-gray': $insu-gray,
		'insu-gray2': $insu-gray2,
		'insu-text': $insu-text,
		'insu-skipped': $insu-skipped,
		'insu-disabled': $insu-disabled,
		'insu-blue': $insu-blue,
		'healthee-text': $healthee-primary,
		'healthee-blue': $healthee-blue,
		'healthee-blue-light': $healthee-blue-light,
		'healthee-blue-transparent': $healthee-blue-transparent,
		'healthee-gradient': $healthee-gradient,
		'healthee-gradient-light': $healthee-gradient-light,
		'healthee-red': $healthee-red,
		'healthee-yellow': $healthee-yellow,
		'healthee-grey-text': $healthee-grey-text,
		'healthee-sidebar-bg': $sidebar-bg,
		'healthee-sidebar-selected-item-bg': $healthee-sidebar-selected-item-bg,
		'healthee-blue-sky': $healthee-blue-sky,
		'healthee-blue-sky-hover': $healthee-blue-sky-hover,
		'healthee-blue-azur': $healthee-blue-azur,
		'healthee-dark-blue': $healthee-dark-blue,
		'healthee-dark-blue-120': $healthee-dark-blue-120,
		'healthee-white-light': $healthee-white-light,
		'healthee-green-light': $healthee-green-light,
		'healthee-primary': $healthee-primary,

		'blue': $blue,
		'indigo': $indigo,
		'purple': $purple,
		'pink': $pink,
		'red': $red,
		'orange': $orange,
		'yellow': $yellow,
		'green': $green,
		'teal': $teal,
		'cyan': $cyan,
		'white': $white,
		'gray': $gray-600,
		'gray-dark': $gray-800,
	),
	$colors
);

$primary: $healthee-primary;
$secondary: $gray-600;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-200;
$dark: $gray-800;

$theme-colors: ();
/* stylelint-disable-next-line scss/dollar-variable-default*/
$theme-colors: map-merge(
	(
		'primary': $primary,
		'secondary': $secondary,
		'success': $success,
		'info': $info,
		'warning': $warning,
		'danger': $danger,
		'pink': $pink,
		'light': $light,
		'dark': $dark,
	),
	$theme-colors
);

/* Set a specific jump point for requesting color jumps*/
$theme-color-interval: 8%;

/* The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.*/
$yiq-contrasted-threshold: 200;

/* Customize the light and dark text colors for use in our YIQ color contrast function.*/
$yiq-text-dark: $gray-900;
$yiq-text-light: $white;

/* Options
/*
/* Quickly modify global styling by enabling or disabling optional features.*/

$enable-caret: false;
$enable-rounded: true;
$enable-shadows: false;
$enable-gradients: false;
$enable-transitions: true;
$enable-prefers-reduced-motion-media-query: true;
$enable-hover-media-query: false; /* Deprecated, no longer affects any compiled CSS*/
$enable-grid-classes: true;
$enable-pointer-cursor-for-buttons: true;
$enable-print-styles: true;
$enable-responsive-font-sizes: false;
$enable-validation-icons: true;
$enable-deprecation-messages: true;

/* Spacing*/

/* Control the default styling of most Bootstrap elements by modifying these*/
/* variables. Mostly focused on spacing.*/
/* You can add more entries to the $spacers map, should you need more variation.*/

$spacer: 1rem;
$spacers: ();
/* stylelint-disable-next-line scss/dollar-variable-default*/
$spacers: map-merge(
	(
		0: 0,
		1: (
			$spacer * 0.25,
		),
		2: (
			$spacer * 0.5,
		),
		3: $spacer,
		4: (
			$spacer * 1.5,
		),
		5: (
			$spacer * 3,
		),
	),
	$spacers
);

/* This variable affects the `.h-*` and `.w-*` classes.*/
$sizes: ();
/* stylelint-disable-next-line scss/dollar-variable-default*/
$sizes: map-merge(
	(
		25: 25%,
		50: 50%,
		75: 75%,
		100: 100%,
		auto: auto,
	),
	$sizes
);

/* Body*/

/* Settings for the `<body>` element.*/

$body-bg: #ffffff;
$body-color: --insu-text;

/* Links*/

/* Style anchor elements.*/

$link-color: theme-color('primary');
$link-decoration: none;
$link-hover-decoration: underline;
/* Darken percentage for links with `.text-*` class (e.g. `.text-success`)*/
$emphasized-link-hover-darken-percentage: 15%;

/* Paragraphs*/

/* Style p element.*/

$paragraph-margin-bottom: 1rem;

/* Grid breakpoints*/

/* Define the minimum dimensions at which your layout will change,*/
/* adapting to different screen sizes, for use in media queries.*/

// $grid-breakpoints: (
//   xs: 0,
//   sm: 576px,
//   md: 768px,
//   lg: 992px,
//   xl: 1200px
// );

// @include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
// @include _assert-starts-at-zero($grid-breakpoints, "$grid-breakpoints");

/* Grid containers*/

/* Define the maximum width of `.container` for different screen sizes.*/

// $container-max-widths: (
//   sm: 540px,
//   md: 720px,
//   lg: 960px,
//   xl: 1140px
// );

// @include _assert-ascending($container-max-widths, "$container-max-widths");

/* Grid columns*/

/* Set the number of columns and specify the width of the gutters.*/

$grid-columns: 12;
$grid-gutter-width: 24px;

/* Components*/

/* Define common padding and border radius sizes and more.*/

$line-height-lg: 1.5;
$line-height-sm: 1.5;

$border-width: 1px;
$border-color: $gray-200;

$border-radius: 1rem;
$border-radius-lg: 0.4rem;
$border-radius-sm: 0.2rem;

$rounded-pill: 50rem;

$box-shadow-sm: 0 1px 1px rgba($black, 0.05);
$box-shadow: 0 2px 4px rgba($black, 0.08);
$box-shadow-lg: 0 5px 12px rgba($black, 0.1);

$component-active-bg: $primary;

$caret-width: 0.3em;
$caret-vertical-align: $caret-width * 0.85;
$caret-spacing: $caret-width * 0.85;

$transition-base: all 0.2s ease-in-out;
$transition-fade: opacity 0.15s linear;
$transition-collapse: height 0.35s ease;

$embed-responsive-aspect-ratios: ();
/* stylelint-disable-next-line scss/dollar-variable-default*/
$embed-responsive-aspect-ratios: join(((21 9), (16 9), (4 3), (1 1)), $embed-responsive-aspect-ratios);

/* Typography*/

/* Font, line-height, and color for body text, headings, and more.*/

/* stylelint-disable value-keyword-case*/

/* stylelint-enable value-keyword-case*/

$font-size-base: 0.9rem;
$font-size-lg: $font-size-base * 1.25;
$font-size-sm: $font-size-base * 0.875;

$font-weight-lighter: lighter;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 600;
$font-weight-bolder: bolder;

$font-weight-base: $font-weight-normal;
$line-height-base: 1.5;

$h1-font-size: $font-size-base * 2.5;
$h2-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base * 1.75;
$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.25;
$h6-font-size: $font-size-base;

$headings-margin-bottom: calc($spacer / 2);
$headings-font-family: null;
$headings-font-weight: 500;
$headings-line-height: 1.2;
$headings-color: null;

$display1-size: 6rem;
$display2-size: 5.5rem;
$display3-size: 4.5rem;
$display4-size: 3.5rem;

$display1-weight: 300;
$display2-weight: 300;
$display3-weight: 300;
$display4-weight: 300;
$display-line-height: $headings-line-height;

$lead-font-size: $font-size-base * 1.25;
$lead-font-weight: 300;

$small-font-size: 80%;

$text-muted: $gray-600;

$blockquote-small-color: $gray-600;
$blockquote-small-font-size: $small-font-size;
$blockquote-font-size: $font-size-base * 1.25;

$hr-border-color: rgba($black, 0.1);
$hr-border-width: $border-width;

$mark-padding: 0.2em;

$dt-font-weight: $font-weight-bold;

$kbd-box-shadow: inset 0 -0.1rem 0 rgba($black, 0.25);
$nested-kbd-font-weight: $font-weight-bold;

$list-inline-padding: 0.5rem;

$mark-bg: #fcf8e3;

$hr-margin-y: $spacer;

/* Tables*/

/* Customizes the `.table` component with basic values, each used across all table variations.*/

$table-cell-padding: 0.75rem;
$table-cell-padding-sm: 0.3rem;

$table-color: $body-color;
$table-bg: null;
$table-accent-bg: $gray-100;
$table-hover-color: $table-color;
$table-hover-bg: $gray-100;
$table-active-bg: $table-hover-bg;

$table-border-width: $border-width;
$table-border-color: $border-color;

$table-head-bg: $gray-100;
$table-head-color: $gray-700;

$table-dark-color: $white;
$table-dark-bg: $gray-800;
$table-dark-accent-bg: rgba($white, 0.05);
$table-dark-hover-color: $table-dark-color;
$table-dark-hover-bg: rgba($white, 0.075);
$table-dark-border-color: lighten($table-dark-bg, 7.5%);
$table-dark-color: $white;

$table-striped-order: odd;

$table-caption-color: $text-muted;

$table-bg-level: -9;
$table-border-level: -6;

/* Buttons + Forms*/

/* Shared variables that are reassigned to `$input-` and `$btn-` specific variables.*/

$input-btn-padding-y: 0.47rem;
$input-btn-padding-x: 0.75rem;
$input-btn-font-family: null;
$input-btn-font-size: $font-size-base;
$input-btn-line-height: $line-height-base;

$input-btn-focus-width: 0.15rem;
$input-btn-focus-color: rgba($component-active-bg, 0.25);
$input-btn-focus-box-shadow: 0 0 0 $input-btn-focus-width $input-btn-focus-color;

$input-btn-padding-y-sm: 0.25rem;
$input-btn-padding-x-sm: 0.5rem;
$input-btn-font-size-sm: $font-size-sm;
$input-btn-line-height-sm: $line-height-sm;

$input-btn-padding-y-lg: 0.5rem;
$input-btn-padding-x-lg: 1rem;
$input-btn-font-size-lg: $font-size-lg;
$input-btn-line-height-lg: $line-height-lg;

$input-btn-border-width: $border-width;

/* Buttons*/

/* For each of Bootstrap's buttons, define text, background, and border color.*/

$btn-padding-y: $input-btn-padding-y;
$btn-padding-x: $input-btn-padding-x;
$btn-font-family: $input-btn-font-family;
$btn-font-size: $input-btn-font-size;
$btn-line-height: $input-btn-line-height;

$btn-padding-y-sm: $input-btn-padding-y-sm;
$btn-padding-x-sm: $input-btn-padding-x-sm;
$btn-font-size-sm: $input-btn-font-size-sm;
$btn-line-height-sm: $input-btn-line-height-sm;

$btn-padding-y-lg: $input-btn-padding-y-lg;
$btn-padding-x-lg: $input-btn-padding-x-lg;
$btn-font-size-lg: $input-btn-font-size-lg;
$btn-line-height-lg: $input-btn-line-height-lg;

$btn-border-width: $input-btn-border-width;

$btn-font-weight: $font-weight-normal;
$btn-box-shadow: inset 0 1px 0 rgba($white, 0.15), 0 1px 1px rgba($black, 0.075);
$btn-focus-width: $input-btn-focus-width;
$btn-focus-box-shadow: $input-btn-focus-box-shadow;
$btn-disabled-opacity: 0.65;
$btn-active-box-shadow: inset 0 3px 5px rgba($black, 0.125);

$btn-link-disabled-color: $gray-600;

$btn-block-spacing-y: 0.5rem;

/* Allows for customizing button radius independently from global border radius*/
$btn-border-radius: $border-radius;
$btn-border-radius-lg: $border-radius-lg;
$btn-border-radius-sm: $border-radius-sm;

$btn-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
	box-shadow 0.15s ease-in-out;

/* Z-index master list*/

/* Warning: Avoid customizing these values. They're used for a bird's eye view*/
/* of components dependent on the z-axis and are designed to all work together.*/

$zindex-dropdown: 1000;
$zindex-sticky: 1020;
$zindex-fixed: 1030;
$zindex-modal-backdrop: 1040;
$zindex-modal: 1050;
$zindex-popover: 1060;
$zindex-tooltip: 1070;

/* Navs*/

$nav-link-padding-y: 0.5rem;
$nav-link-padding-x: 1rem;
$nav-link-disabled-color: $gray-600;

$nav-tabs-border-color: $gray-400;
$nav-tabs-border-width: $border-width;
$nav-tabs-border-radius: $border-radius;
$nav-tabs-link-hover-border-color: $gray-200 $gray-200 $nav-tabs-border-color;
$nav-tabs-link-active-color: $gray-700;
$nav-tabs-link-active-bg: $card-bg;
$nav-tabs-link-active-border-color: $gray-400 $gray-400 $nav-tabs-link-active-bg;

$nav-pills-border-radius: $border-radius;
$nav-pills-link-active-color: $component-active-color;
$nav-pills-link-active-bg: $component-active-bg;

$nav-divider-color: $gray-200;
$nav-divider-margin-y: calc($spacer / 2);

/* Navbar*/

$navbar-padding-y: calc($spacer / 2);
$navbar-padding-x: $spacer;

$navbar-nav-link-padding-x: 0.5rem;

$navbar-brand-font-size: $font-size-lg;
/* Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link*/
$nav-link-height: calc($font-size-base * $line-height-base + $nav-link-padding-y * 2);
$navbar-brand-height: $navbar-brand-font-size * $line-height-base;
$navbar-brand-padding-y: calc(($nav-link-height - $navbar-brand-height) / 2);

$navbar-toggler-padding-y: 0.25rem;
$navbar-toggler-padding-x: 0.75rem;
$navbar-toggler-font-size: $font-size-lg;
$navbar-toggler-border-radius: $btn-border-radius;

$navbar-dark-color: rgba($white, 0.5);
$navbar-dark-hover-color: rgba($white, 0.75);
$navbar-dark-active-color: $white;
$navbar-dark-disabled-color: rgba($white, 0.25);
$navbar-dark-toggler-icon-bg: str-replace(
	url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http:/*www.w3.org/2000/svg'%3e%3cpath stroke='#{$navbar-dark-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"),
	'#',
	'%23'
);
$navbar-dark-toggler-border-color: rgba($white, 0.1);

$navbar-light-color: rgba($black, 0.5);
$navbar-light-hover-color: rgba($black, 0.7);
$navbar-light-active-color: rgba($black, 0.9);
$navbar-light-disabled-color: rgba($black, 0.3);
$navbar-light-toggler-icon-bg: str-replace(
	url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http:/*www.w3.org/2000/svg'%3e%3cpath stroke='#{$navbar-light-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"),
	'#',
	'%23'
);
$navbar-light-toggler-border-color: rgba($black, 0.1);

$navbar-light-brand-color: $navbar-light-active-color;
$navbar-light-brand-hover-color: $navbar-light-active-color;
$navbar-dark-brand-color: $navbar-dark-active-color;
$navbar-dark-brand-hover-color: $navbar-dark-active-color;

/* Tooltips*/

$tooltip-font-size: $font-size-sm;
$tooltip-max-width: 200px;
$tooltip-color: $white;
$tooltip-bg: $black;
$tooltip-border-radius: $border-radius;
$tooltip-opacity: 0.9;
$tooltip-padding-y: 0.4rem;
$tooltip-padding-x: 0.7rem;
$tooltip-margin: 0;

$tooltip-arrow-width: 0.8rem;
$tooltip-arrow-height: 0.4rem;
$tooltip-arrow-color: $tooltip-bg;

/* Form tooltips must come after regular tooltips*/
$form-feedback-tooltip-padding-y: $tooltip-padding-y;
$form-feedback-tooltip-padding-x: $tooltip-padding-x;
$form-feedback-tooltip-font-size: $tooltip-font-size;
$form-feedback-tooltip-line-height: $line-height-base;
$form-feedback-tooltip-opacity: $tooltip-opacity;
$form-feedback-tooltip-border-radius: $tooltip-border-radius;

/* Modals*/

/* Padding applied to the modal body*/
$modal-inner-padding: 1rem;

$modal-dialog-margin: 0.5rem;
$modal-dialog-margin-y-sm-up: 1.75rem;

$modal-title-line-height: $line-height-base;

$modal-content-color: null;
$modal-content-bg: $white;
$modal-content-border-color: $gray-300;
$modal-content-border-width: $border-width;
$modal-content-border-radius: $border-radius-lg;
$modal-content-box-shadow-xs: 0 0.25rem 0.5rem rgba($black, 0.5);
$modal-content-box-shadow-sm-up: 0 0.5rem 1rem rgba($black, 0.5);

$modal-backdrop-bg: $black;
$modal-backdrop-opacity: 0.5;
$modal-header-border-color: $border-color;
$modal-footer-border-color: $modal-header-border-color;
$modal-header-border-width: $modal-content-border-width;
$modal-footer-border-width: $modal-header-border-width;
$modal-header-padding-y: 1rem;
$modal-header-padding-x: 1rem;
$modal-header-padding: $modal-header-padding-y $modal-header-padding-x; /* Keep this for backwards compatibility*/

$modal-xl: 1140px;
$modal-lg: 800px;
$modal-md: 500px;
$modal-sm: 300px;

$modal-fade-transform: translate(0, -50px);
$modal-show-transform: none;
$modal-transition: transform 0.3s ease-out;
