// Colors
$healthee-blue: #007eff;
$dark-blue: #00264c;
$dark-blue-120: #001e3d;
$dark-blue-shade-1: #405c79;
$dark-blue-shade-2: #8092a5;
$input-border: #dde0e2;
$border-color-light-blue: #dee7f2;
$border-color-light-gray: #dde0e2;
$healthee-vermilion: #fe5201;
$healthee-vermilion-60: #fe9767;
$healthee-vermilion-20: #ffdccc;
$healthee-vermilion-5: #fff6f2;
$healthee-yellow: #ffb601;
$healthee-yellow-60: #ffd367;
$healthee-yellow-20: #fff0cc;
$healthee-yellow-5: #fffbf2;
$azure-radiance-120: #0065cc;
$azure-radiance: #007eff;
$azure-radiance-60: #66b2ff;
$azure-radiance-20: #cce5ff;
$azure-radiance-5: #f2f9ff;
$success: #3bab18;
$success-5: #f5fbf3;
$error: #ca1414;
$error-5: #fdf6f6;
$white: #fff;

$text-color-primary: $dark-blue-120;
$text-color-secondary: $dark-blue-shade-1;

$gradient-light-blue: linear-gradient(179.2deg, #f7fbff 5.73%, #ffffff 37.7%);

$color-set: (
	($healthee-blue, 'healthee-blue'),
	($dark-blue, 'dark-blue'),
	($dark-blue-shade-1, 'dark-blue-shade-1'),
	($dark-blue-shade-2, 'dark-blue-shade-2'),
	($dark-blue-120, 'dark-blue-120'),
	($input-border, 'input-border'),
	($healthee-vermilion, 'healthee-vermilion'),
	($healthee-vermilion-60, 'healthee-vermilion-60'),
	($healthee-vermilion-20, 'healthee-vermilion-20'),
	($healthee-vermilion-5, 'healthee-vermilion-5'),
	($healthee-yellow, 'healthee-yellow'),
	($healthee-yellow-60, 'healthee-yellow-60'),
	($healthee-yellow-20, 'healthee-yellow-20'),
	($healthee-yellow-5, 'healthee-yellow-5'),
	($azure-radiance-120, 'azure-radiance-120'),
	($azure-radiance, 'azure-radiance'),
	($azure-radiance-60, 'azure-radiance-60'),
	($azure-radiance-20, 'azure-radiance-20'),
	($azure-radiance-5, 'azure-radiance-5'),
	($border-color-light-blue, 'border-color-light-blue'),
	($border-color-light-gray, 'border-color-light-gray'),
	($success, 'success'),
	($error, 'error'),
	($white, 'white')
);

// Since we have mobile based on the same codebase, we should follow mobile-first approach
// i.e we start building from the most restricting env (small phones) to least (large desktops)

$screen-size-extra-large: 1440px; // Extra large screens, TV
$screen-size-large: 1024px; // Desktops, large screens
$screen-size-medium: 769px; // Small screens, laptops
$screen-size-small: 500px; // iPads, Tablets

@mixin minWidth($width) {
	@media screen and (min-width: $width) {
		@content;
	}
}

@mixin maxWidth($width) {
	@media screen and (max-width: $width) {
		@content;
	}
}

@mixin whenXL() {
	@include minWidth($screen-size-extra-large) {
		@content;
	}
}

@mixin whenLG() {
	@include minWidth($screen-size-large) {
		@content;
	}
}

@mixin whenMD() {
	@include minWidth($screen-size-medium) {
		@content;
	}
}

@mixin whenSM() {
	@include minWidth($screen-size-small) {
		@content;
	}
}
@mixin tillXL() {
	@include maxWidth($screen-size-extra-large) {
		@content;
	}
}

@mixin tillLG() {
	@include maxWidth($screen-size-large) {
		@content;
	}
}

@mixin tillMD() {
	@include maxWidth($screen-size-medium) {
		@content;
	}
}

@mixin tillSM() {
	@include maxWidth($screen-size-small) {
		@content;
	}
}

@mixin whenPortrait() {
	@media screen and (orientation: portrait) {
		@content;
	}
}

@mixin whenLandscape() {
	@media screen and (orientation: landscape) {
		@content;
	}
}

// Util css classes

@mixin genDisplayUtils($breakpoint-prefix) {
	$display-set: ('flex', 'block', 'inline-block', 'none');

	@each $display in $display-set {
		.#{$breakpoint-prefix}d-#{$display} {
			display: #{$display} !important;
		}
	}
}

@mixin genFontSizes($breakpoint-prefix) {
	@for $i from 8 to 40 {
		.#{$breakpoint-prefix}font-size-#{$i} {
			font-size: #{$i}px !important;
		}
	}
}

@mixin genIconSizes() {
	@for $i from 5 to 70 {
		.icon-size-#{$i} {
			font-size: #{$i}px !important;
			width: #{$i}px !important;
			height: #{$i}px !important;
		}
	}
}

@mixin genColorClasses($breakpoint-prefix) {
	$color-classes: (('background', 'background-color'), ('border-color', 'border-color'), ('text', 'color'));

	@each $color in $color-set {
		@each $class in $color-classes {
			.#{$breakpoint-prefix}#{nth($class, 1)}-#{nth($color, 2)} {
				#{nth($class, 2)}: #{nth($color, 1)} !important;
			}
		}
	}
}

@mixin genFontWeights() {
	.font-weight-700 {
		font-weight: 700 !important;
	}

	.font-weight-500 {
		font-weight: 500 !important;
	}
}

@mixin genPaddings($breakpoint-prefix) {
	@for $i from 0 to 150 {
		.#{$breakpoint-prefix}pr-#{$i} {
			padding-right: #{$i}px !important;
		}

		.#{$breakpoint-prefix}pb-#{$i} {
			padding-bottom: #{$i}px !important;
		}

		.#{$breakpoint-prefix}pl-#{$i} {
			padding-left: #{$i}px !important;
		}

		.#{$breakpoint-prefix}pt-#{$i} {
			padding-top: #{$i}px !important;
		}

		.#{$breakpoint-prefix}p-#{$i} {
			padding: #{$i}px !important;
		}

		.#{$breakpoint-prefix}px-#{$i} {
			padding-left: #{$i}px !important;
			padding-right: #{$i}px !important;
		}

		.#{$breakpoint-prefix}py-#{$i} {
			padding-top: #{$i}px !important;
			padding-bottom: #{$i}px !important;
		}
	}
}

@mixin genMargins($breakpoint-prefix) {
	@for $i from 0 to 50 {
		.#{$breakpoint-prefix}mr-#{$i} {
			margin-right: #{$i}px !important;
		}

		.#{$breakpoint-prefix}mb-#{$i} {
			margin-bottom: #{$i}px !important;
		}

		.#{$breakpoint-prefix}mt-#{$i} {
			margin-top: #{$i}px !important;
		}

		.#{$breakpoint-prefix}ml-#{$i} {
			margin-left: #{$i}px !important;
		}

		.#{$breakpoint-prefix}mx-#{$i} {
			margin-left: #{$i}px !important;
			margin-right: #{$i}px !important;
		}

		.#{$breakpoint-prefix}my-#{$i} {
			margin-top: #{$i}px !important;
			margin-bottom: #{$i}px !important;
		}
	}
}

@mixin genRadius($breakpoint-prefix) {
	@for $i from 8 to 61 {
		.#{$breakpoint-prefix}radius-#{$i} {
			border-radius: #{$i}px !important;
		}
	}

	.radius-circle {
		border-radius: 50% !important;
	}
}

@mixin genBorders($breakpoint-prefix) {
	.border-1 {
		border-width: 1px !important;
	}

	.border-style-solid {
		border-style: solid !important;
	}

	// border shortcuts
	@each $color in $color-set {
		.#{$breakpoint-prefix}border-#{nth($color, 2)} {
			border: 1px solid #{nth($color, 1)} !important;
		}

		.#{$breakpoint-prefix}border-bottom-#{nth($color, 2)} {
			border-bottom: 1px solid #{nth($color, 1)} !important;
		}

		.#{$breakpoint-prefix}border-left-#{nth($color, 2)} {
			border-left: 1px solid #{nth($color, 1)} !important;
		}

		.#{$breakpoint-prefix}border-right-#{nth($color, 2)} {
			border-right: 1px solid #{nth($color, 1)} !important;
		}
	}
}

@mixin genShadows() {
	.shadow-1 {
		box-shadow: 0 0 32px rgba(0, 0, 0, 0.08) !important;
	}
	.shadow-2 {
		box-shadow: 0 0 12px rgba(0, 34, 68, 0.06) !important;
	}
	.shadow-3 {
		box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15) !important;
	}
}

@mixin genFlexUtils($breakpoint-prefix) {
	.#{$breakpoint-prefix}flex-1 {
		flex: 1 !important;
	}

	$align-set: (flex-start, flex-end, center, baseline, stretch);

	@each $align in $align-set {
		.#{$breakpoint-prefix}align-items-#{$align} {
			align-items: #{$align} !important;
		}
	}

	$direction-set: (column, row, row-reverse, column-reverse);

	@each $direction in $direction-set {
		.#{$breakpoint-prefix}flex-direction-#{$direction} {
			flex-direction: #{$direction};
		}
	}

	$content-set: (center, space-around, space-between, flex-start, flex-end);

	@each $content in $content-set {
		.#{$breakpoint-prefix}justify-content-#{$content} {
			justify-content: #{$content} !important;
		}
	}
}

@mixin genCursors() {
	.cursor-pointer {
		cursor: pointer !important;
	}

	.cursor-not-allowed {
		cursor: url('assets/icons/cursors/not-allowed.svg'), not-allowed !important;
	}
}

@mixin genTextAlignUtils($breakpoint-prefix) {
	.#{$breakpoint-prefix}text-align-center {
		text-align: center;
	}

	.#{$breakpoint-prefix}text-align-left {
		text-align: left;
	}

	.#{$breakpoint-prefix}text-align-right {
		text-align: left;
	}
}

@mixin genWidths($breakpoint-prefix) {
	.#{$breakpoint-prefix}width-fit {
		width: 100% !important;
		max-width: 100% !important;
	}

	@for $i from 5 to 250 {
		.#{$breakpoint-prefix}width-#{$i} {
			width: #{$i}px !important;
		}

		.#{$breakpoint-prefix}max-width-#{$i} {
			max-width: #{$i}px !important;
		}

		.#{$breakpoint-prefix}min-width-#{$i} {
			min-width: #{$i}px !important;
		}
	}

	.max-width-none {
		max-width: none !important;
	}
}

@mixin genHeights($breakpoint-prefix) {
	.#{$breakpoint-prefix}height-fit {
		height: 100% !important;
		max-height: 100% !important;
	}

	@for $i from 5 to 250 {
		.#{$breakpoint-prefix}height-#{$i} {
			height: #{$i}px !important;
		}

		.#{$breakpoint-prefix}max-height-#{$i} {
			max-height: #{$i}px !important;
		}

		.#{$breakpoint-prefix}min-height-#{$i} {
			min-height: #{$i}px !important;
		}
	}
}

@mixin genPositions($breakpoint-prefix) {
	$positions: (relative, absolute, fixed);

	@each $pos in $positions {
		.#{$breakpoint-prefix}position-#{$pos} {
			position: #{$pos} !important;
		}
	}
}

@mixin genMisc($breakpoint-prefix) {
	.#{$breakpoint-prefix}white-space-nowrap {
		white-space: nowrap !important;
	}

	.#{$breakpoint-prefix}text-ellipsis {
		white-space: nowrap !important;
		overflow: hidden !important;
		text-overflow: ellipsis !important;
	}
}

@mixin genUtilClasses() {
	@include genDisplayUtils('');
	@include genColorClasses('');
	@include genFontSizes('');
	@include genFlexUtils('');
	@include genMargins('');
	@include genTextAlignUtils('');
	@include genRadius('');
	@include genPaddings('');
	@include genBorders('');
	@include genWidths('');
	@include genHeights('');
	@include genMisc('');
	@include genPositions('');

	@include whenSM() {
		$prefix: 'sm-';
		@include genDisplayUtils($prefix);
		@include genColorClasses($prefix);
		@include genFontSizes($prefix);
		@include genFlexUtils($prefix);
		@include genMargins($prefix);
		@include genTextAlignUtils($prefix);
		@include genRadius($prefix);
		@include genPaddings($prefix);
		@include genBorders($prefix);
		@include genWidths($prefix);
		@include genHeights($prefix);
		@include genMisc($prefix);
		@include genPositions($prefix);
	}

	@include whenMD() {
		$prefix: 'md-';
		@include genDisplayUtils($prefix);
		@include genColorClasses($prefix);
		@include genFontSizes($prefix);
		@include genFlexUtils($prefix);
		@include genMargins($prefix);
		@include genTextAlignUtils($prefix);
		@include genRadius($prefix);
		@include genPaddings($prefix);
		@include genBorders($prefix);
		@include genWidths($prefix);
		@include genHeights($prefix);
		@include genMisc($prefix);
		@include genPositions($prefix);
	}

	@include whenLG() {
		$prefix: 'lg-';
		@include genDisplayUtils($prefix);
		@include genColorClasses($prefix);
		@include genFontSizes($prefix);
		@include genFlexUtils($prefix);
		@include genMargins($prefix);
		@include genTextAlignUtils($prefix);
		@include genRadius($prefix);
		@include genPaddings($prefix);
		@include genBorders($prefix);
		@include genWidths($prefix);
		@include genHeights($prefix);
		@include genMisc($prefix);
		@include genPositions($prefix);
	}

	@include whenXL() {
		$prefix: 'xl-';
		@include genDisplayUtils($prefix);
		@include genColorClasses($prefix);
		@include genFontSizes($prefix);
		@include genFlexUtils($prefix);
		@include genMargins($prefix);
		@include genTextAlignUtils($prefix);
		@include genRadius($prefix);
		@include genPaddings($prefix);
		@include genBorders($prefix);
		@include genWidths($prefix);
		@include genHeights($prefix);
		@include genMisc($prefix);
		@include genPositions($prefix);
	}

	@include genFontWeights;
	@include genShadows;
	@include genIconSizes;
	@include genCursors;
}
