.cdk-overlay-container .cdk-overlay-dark-backdrop {
	background-color: rgba(0, 27, 54, 0.4);
}

.cdk-overlay-pane {
	&.healthee-dialog-wrapper {
		mat-dialog-container.mat-dialog-container {
			padding: 1rem !important;
			border-radius: 1rem;
			height: max-content;
			margin: auto 0;
		}
	}
	&.healthee-dialog-wrapper-no-padding {
		mat-dialog-container.mat-dialog-container {
			padding: 0rem !important;
		}
	}

	&.healthee-bottom-sheet {
		mat-bottom-sheet-container {
			max-height: 100vh;
			overflow: hidden;
			padding: 1rem !important;
			border-radius: 1rem 1rem 0 0;
		}
	}

	&.healthee-bottom-sheet-no-padding {
		mat-bottom-sheet-container {
			padding: 0rem !important;
		}
	}

	&.width85 {
		width: 85vw;
	}

	&.bubbledialog {
		position: absolute !important;
		left: 0;
		bottom: 0;
		min-width: 100vw;
		max-width: 100vw !important;
		width: 100vw;

		mat-dialog-container.mat-dialog-container {
			color: var(--insu-bg);
			background-color: var(--insu-text);
			border-radius: 25px 25px 0px 0px !important;
			box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.2);
			height: auto;
			padding: 1.2rem;
		}
	}

	&.dialog-wrapper {
		mat-dialog-container.mat-dialog-container {
			padding: 1rem !important;
			background-color: var(--insu-bg) !important;
			border-radius: 1.25rem;
			height: max-content;
			margin: auto 0;
		}
	}

	&.talon-dialog-wrapper {
		width: 60vw;
		.mat-dialog-container {
			background-color: var(--insu-bg);
		}
	}
}

.delete-dialog {
	background: #f5f6fd;
	border-radius: 25px;
	*:focus {
		outline: none;
	}
	.dialog-skip {
		border: 2px solid #3dcbe1;
		background: #ffffff;
		border-radius: 20px;
		color: #3dcbe1;
		height: 2.25rem;
		font-weight: bold;
		padding: 0px 24px;
		cursor: pointer;
	}
	.dialog-delete {
		background: #3dcbe1;
		border-radius: 20px;
		border: none;
		height: 2.25rem;
		color: white;
		padding: 0px 24px;
		cursor: pointer;
	}
}
.mat-select {
	.mat-select-arrow {
		border: none;
	}

	.mat-select-trigger {
		height: 100%;
		z-index: 1;
	}
	.mat-select-panel {
		position: relative;
		top: 3rem;
		border-radius: 20px;
		padding: 0.2rem 0;
	}
}

@media (max-width: 500px) {
	.cdk-overlay-pane {
		&.healthee-dialog-wrapper {
			mat-dialog-container.mat-dialog-container {
				width: 100%;
				height: 100%;
			}
		}
		&.width85 {
			width: 100vw;
		}

		&.chat-dialog-wrapper {
			width: 95vw;
		}
		&.dialog-wrapper {
			max-width: 100vw !important;
			padding: 0.5rem 1rem;
			height: 100%;
		}
		&.talon-dialog-wrapper {
			width: 95vw;
		}
		&.terms-container {
			width: 100%;
		}
	}
}

.main-content {
	overflow: hidden;
	.content {
		padding: 0 15px 10px 15px;
		margin-top: $header-height;
	}
}

.menu-title {
	padding: 12px 20px !important;
	letter-spacing: 0.05em;
	pointer-events: none;
	cursor: default;
	font-size: 11px;
	text-transform: uppercase;
	color: $sidebar-menu-item-icon-color;
	font-weight: $font-weight-semibold;
	opacity: 0.5;
}
