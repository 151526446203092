@use '../../../utils.scss';
$height-sm: 2.5rem;
$height-md: 3rem;
$height-lg: 3.25;

$padding-sm: 0.5rem 1.5rem;
$padding-md: 0.5rem 1.5rem;
$padding-lg: 0.5rem 2rem;

$font-size-sm: 0.875rem;
$font-size-md: 0.875rem;
$font-size-lg: 1rem;

$border-radius: 2rem;

// ================================ BASE BUTTON =================================

a.healthee-btn {
	text-decoration: none;
}

button.healthee-btn,
a.healthee-btn {
	display: flex;
	align-items: center;
	justify-content: center;
	width: max-content;
	background-color: transparent;
	color: utils.$white;
	font-weight: 400;
	border-radius: $border-radius;
	border: 0;
	outline: 0;
	cursor: pointer;
	padding: 0;
	gap: 0.5rem;

	// small btn is default
	height: $height-sm;
	font-size: $font-size-sm;
	padding: $padding-sm;

	&.sm {
		height: $height-sm;
		font-size: $font-size-sm;
		padding: $padding-sm;
	}

	&.md {
		height: $height-md;
		font-size: $font-size-md;
		padding: $padding-md;
	}

	&.lg {
		height: $height-md;
		font-size: $font-size-md;
		padding: $padding-md;
	}

	&:disabled {
		cursor: default;
		pointer-events: none;
	}

	&:focus {
		outline: 0;
	}
}

// ================================ PRIMARY BUTTON =================================

button.healthee-btn.primary,
a.healthee-btn.primary {
	color: utils.$white;
	background-color: utils.$azure-radiance;

	&:focus-visible {
		background-color: utils.$dark-blue;
	}

	&:hover:not(:disabled):not(:active) {
		background-color: utils.$azure-radiance-120;
	}

	&:active {
		background-color: utils.$azure-radiance;
	}

	&:disabled {
		background-color: utils.$azure-radiance-20;
	}
}

// ================================ SECONDARY BUTTON =================================

button.healthee-btn.secondary,
a.healthee-btn.secondary {
	color: utils.$azure-radiance;
	background-color: utils.$white;
	border: 1px solid utils.$border-color-light-blue;

	&:focus-visible {
		color: utils.$azure-radiance;
		background-color: utils.$azure-radiance-5;
		border-color: utils.$dark-blue;
	}

	&:hover:not(:disabled):not(:active) {
		color: utils.$azure-radiance-120;
		background-color: utils.$azure-radiance-5;
		border-color: utils.$azure-radiance-120;
	}

	&:active {
		color: utils.$white;
		background-color: utils.$azure-radiance;
		border-color: utils.$azure-radiance;
	}

	&:disabled {
		color: utils.$azure-radiance-60;
		background-color: utils.$white;
		border-color: utils.$azure-radiance-60;
	}
}

// ================================ TERITARY BUTTON =================================

button.healthee-btn.tertiary,
a.healthee-btn.tertiary {
	color: utils.$azure-radiance;
	background-color: utils.$white;
	border: 1px solid utils.$border-color-light-blue;

	&:focus-visible {
		border-color: utils.$dark-blue;
	}

	&:hover:not(:disabled):not(:active) {
		color: utils.$azure-radiance-120;
	}

	&:active {
		border-color: utils.$azure-radiance;
	}

	&:disabled {
		color: utils.$azure-radiance-60;
	}
}
